















import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import Badge from '@/components/atoms/quiz/Badge.vue'
import ModalShowBadge from '@/components/organisms/quiz/ModalShowBadge.vue'

@Component({
  components: {
    Badge,
    ModalShowBadge,
  },
})
export default class ListBadge extends Vue {
  @Prop()
  private badges!: any[]

  @Ref() modalBadge!: ModalShowBadge

  private badgeCurrent = null

  private selectBadge(badge: any) {
    this.badgeCurrent = badge
    this.modalBadge.show()
  }

}
