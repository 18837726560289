







import { Component, Vue } from 'vue-property-decorator'
import ListStatistic from '@/components/organisms/quiz/ListStatistic.vue'
import ListBadge from '@/components/organisms/quiz/ListBadge.vue'

@Component({
  components: {
    ListStatistic,
    ListBadge,
  },
})
export default class Achievements extends Vue {

  private statistics = {}
  private badges = []

  private async getStatistics(){
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/history_quiz/result_statistics/learning_status')
    if(result.data) {
      this.statistics = result.data.data
    }
  }

  private async getListBadges(){
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/history_quiz/badges')
    if(result.data) {
      this.badges = result.data.data
    }
  }

  private mounted() {
    this.getStatistics()
    this.getListBadges()
  }
}
