































import { Component, Prop, Vue } from 'vue-property-decorator'
import DonutOverview from './DonutOverview.vue'
import LabelChart from '@/components/atoms/quiz/LabelChart.vue'

@Component({
  components: {
    DonutOverview,
    LabelChart,
  },
})
export default class ListStatistic extends Vue {
  @Prop()
  private statistics!: {
    studied_days: number
    consecutive_studied_days: number
    max_consecutive_studied_days: number
    rank: {
      name: string
      icon: string
    }
    correct_count: number
    incorrect_count: number
    not_drill_count: number
    studied_turns: number
  }

  private get dataSource() {
    if (!this.statistics || !Object.keys(this.statistics).length) {
      return []
    }
    return [this.statistics.correct_count, this.statistics.incorrect_count, this.statistics.not_drill_count]
  }

  private get statisticsInfo() {
    return [
      {
        name: '総学習問題数',
        amount: this.statistics.studied_turns || 0,
        unit: '問',
      },
      {
        name: '総学習日数',
        amount: this.statistics.studied_days,
      },
      {
        name: '連続学習日数',
        amount: this.statistics.consecutive_studied_days,
      },
      {
        name: '最大連続学習日数',
        amount: this.statistics.max_consecutive_studied_days,
      },
    ]
  }
}
